import { Injectable } from '@angular/core';
import {RootService} from '../root.service';
import {HttpClient} from '@angular/common/http';
import {SiteService} from '../siteService/site.service';
import {DeliveryDto} from "../../dtos/delivery-dto";
import {DeliveriesWithNext} from "../../dtos/deliveriesWithNext";
import {UserService} from "../userService/user.service";

@Injectable({
  providedIn: 'root'
})
export class DeliveriesService extends RootService{

  public deliveriesFromMySite: DeliveryDto[] = [];
  public containerSelected: DeliveryDto = null;

  constructor(
    public http: HttpClient,
    public siteService: SiteService,
    public userService: UserService
  ) { super(http); }


  getDeliveryWpFromMySite(){
    this.http.get(this.URL + 'deliveries/site/' + this.siteService.siteSelected.id + '/sender').subscribe((deliverySend: DeliveriesWithNext) => {
      this.deliveriesFromMySite = deliverySend.dtos.filter(x => x.createdBy === this.userService.user.login);
    });
  }

  findProduct(code){
    return this.http.get(this.URL + 'products/wild/search/product/' + code);
  }

  searchDelivery(productCode){
    return this.http.get(this.URL + 'products/search/product/' + productCode);
  }

}
