import {Injectable} from '@angular/core';
import {RootService} from '../root.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from '../userService/user.service';
import {SiteService} from '../siteService/site.service';
import {DeliveriesService} from '../deliveriesService/deliveries.service';
import {Container} from '../../../entity/classes/container/container';
import {ContainerType} from '../../../entity/classes/containerType/container-type';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ColisageService extends RootService{


  constructor(
    public http: HttpClient,
    public userService: UserService,
    public siteService: SiteService,
    public deliveriesService: DeliveriesService,
    public toastController: ToastController
    ) { super(http);}


  saveColisage() {
    const obj = this.deliveriesService.deliveriesFromMySite.filter(x => x.id == null)

    if(obj.length > 0){
      let i = 0;
      for(const delivery of obj){
        this.containerIsRef(delivery.containerName).then(() => {
          i++;
          if(i === obj.length){
            this.createDelivery().subscribe((resCreateDeliveries)=>{
              this.replaceDelivery(resCreateDeliveries).then(() => {
                this.insertProduct();
              })
            });
          }
        });
      }
    }else{
      this.insertProduct();
    }
  }

  replaceDelivery(newDelivery){
    return new Promise((res, rej) => {
      let i = 0;
      for(const delivery of newDelivery){
        const findDelivery = this.deliveriesService.deliveriesFromMySite.findIndex(x => x.containerName === delivery.containerName);
        if(findDelivery !== -1){
          delivery.productDeliveries = this.deliveriesService.deliveriesFromMySite[findDelivery].productDeliveries;
          this.deliveriesService.deliveriesFromMySite.splice(findDelivery, 1, delivery);
        }
        i++;
        if(i === newDelivery.length){res(null);}
      }
    });
  }

  containerIsRef(containerName){
    return new Promise((res, rej) => {
      this.http.get(this.URL + 'containers/bar-code/' + containerName).subscribe((resGetContainer) => {

        if(!resGetContainer){
          this.createContainer(containerName).subscribe((resCreateContainer) => {
            res(containerName);
          });
        } else{
          res(null);
        }
      });
    });
  }

  createContainer(containerName) {
    const newContainer = new Container();
    newContainer.name = containerName;
    newContainer.barCode = containerName;
    newContainer.containerType = new ContainerType();
    newContainer.containerType.id = 144; // containerType par defaut A CHANGER

    return this.http.post(this.URL + 'containers/list', [newContainer]);
  }

  createDelivery(){
    const deliveriesToCreate = this.deliveriesService.deliveriesFromMySite.filter(x => x.id == null);
    const justString = deliveriesToCreate.map(x => x.containerName);

    return this.http.post(this.URL + 'deliveries/site/' + this.siteService.siteSelected.id + '/open-containers', justString);
  }

  insertProduct() {
    let i = 0;
    for(const delivery of this.deliveriesService.deliveriesFromMySite){
      const productFilter = delivery.productDeliveries.filter(x => x.id == null);
      productFilter.map((x:any) => ({
          id: x.id,
          messageCode: x.messageCode,
          messageDescription: x.messageDescription,
          name: x.name,
          urgent: false
        }));

      if(productFilter.length > 0){
        this.http.put(this.URL + 'deliveries/site/' + this.siteService.siteSelected.id + '/delivery/' + delivery.id + '/insert-products', productFilter)
          .subscribe(async (resInsertProduct) => {
            i++;
            if (i === this.deliveriesService.deliveriesFromMySite.length) {
              const toast = await this.toastController.create({
                message: 'Vos produits sont bien enregistrés.',
                duration: 4000,
              });
              toast.present();
            }
          });
      }
    }
  }
}
