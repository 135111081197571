import {ProductDeliveryDto} from '../../../gateways/dtos/productDelivery-dto';
import {StatusDeliveriesEnum} from "../../enum/statusDeliveries-enum";

export class ProductDelivery implements ProductDeliveryDto{
  barCode: string;
  carId: number;
  containerId: number;
  deliverAt: string;
  deliveryId: number;
  destinationSiteId: number;
  id: number;
  messageCode: string;
  messageDescription: string;
  name: string;
  pickupAt: string;
  productId: number;
  roundId: number;
  senderSiteId: number;
  status: StatusDeliveriesEnum;
  targetDeliverTime: string;
  targetPickupTime: string;
  transportationCompanyId: number;
  userReceiverId: number;
  userSenderId: number;

  constructor(productDelivery?: ProductDelivery) {
    if (productDelivery) { Object.assign(this, productDelivery); }
    else {
      Object.assign(this, {
        barCode: '', carId: null, containerId: null, deliverAt: '', deliveryId: null, destinationSiteId: null,
        id: null, messageCode: null, messageDescription: null, name: '', pickupAt: '', productId: null, roundId: null,
        senderSiteId: null, status: StatusDeliveriesEnum.normal, targetDeliverTime: '', targetPickupTime: '', transportationCompanyId: null,
        userReceiverId: null, userSenderId: null
      });
    }
  }

  convertToProductDto() {
    return {
      id: this.id, name: this.barCode, messageCode: this.messageDescription,
      messageDescription: this.messageDescription, urgent: false
    };
  }

}
