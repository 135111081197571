export enum StatusDeliveriesEnum {
  waiting_pickup = 'WAITING_PICKUP',
  in_transit = 'IN_TRANSIT',
  in_progress = 'IN_PROGRESS',
  waiting_connection = 'WAITING_CONNECTION',
  deliver = 'DELIVER',
  deliver_abnormal = 'DELIVER_ABNORMAL',
  waiting_reception = 'WAITING_RECEPTION',
  normal = 'NORMAL'
}
