import { Component, OnInit } from '@angular/core';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import {AlertController, ModalController} from "@ionic/angular";
import {PlatformService} from "../../gateways/services/platformService/platform.service";

@Component({
  selector: 'app-modal-open-container',
  templateUrl: './modal-open-container.page.html',
  styleUrls: ['./modal-open-container.page.scss'],
})
export class ModalOpenContainerPage implements OnInit {

  constructor(
    private barcodeScanner: BarcodeScanner,
    public modalController: ModalController,
    public alertController: AlertController,
    public platformService: PlatformService
  ) { }

  ngOnInit() {
  }

  async openInput() {
    const alert = await this.alertController.create({
      header: 'Entrez le code',
      inputs: [
        {
          name: 'barrecode',
          type: 'text',
          placeholder: 'ex: 00976HHD6HZJ'
        }
      ],
      buttons: [
        {
          text: 'Annuler',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.closeModal();
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            this.closeModal(data.barrecode);
          }
        }
      ]
    });

    await alert.present();
  }

  openScanner() {
    this.barcodeScanner.scan().then(barcodeData => {
      this.closeModal(barcodeData.text);
    }).catch(err => {
      console.log('Error', err);
    });
  }

  closeModal(container?: string){
    this.modalController.dismiss({
      barCode: container
    });
  }
}
