import { Component, OnInit } from '@angular/core';
import {DeliveriesService} from "../../gateways/services/deliveriesService/deliveries.service";
import {PlatformService} from "../../gateways/services/platformService/platform.service";

@Component({
  selector: 'app-suivi-deliver',
  templateUrl: './suivi-deliver.page.html',
  styleUrls: ['./suivi-deliver.page.scss'],
})
export class SuiviDeliverPage implements OnInit {

  constructor(
    public deliveriesService: DeliveriesService,
    public platformService: PlatformService
  ) { }

  ngOnInit() {}

}
