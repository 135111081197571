import {ContainerDto} from "../../../gateways/dtos/container-dto";
import {ContainerTypeDto} from "../../../gateways/dtos/containerType-dto";

export class Container implements ContainerDto{
  barCode: string;
  companyId: number;
  containerType: ContainerTypeDto;
  currentCarId: number;
  currentSiteId: number;
  destinationId: number;
  emergency: boolean;
  id: number;
  name: string;
  status: string;
  urgent: boolean;

  constructor(container?: Container) {
    if (container) { Object.assign(this, container); }
    else {
      Object.assign(this, {
        barCode: '',
        companyId: null,
        containerType: {},
        currentCarId: null,
        currentSiteId: null,
        destinationId: null,
        emergency: false,
        id: null,
        name: '',
        status: 'NEW',
        urgent: false
      });
    }
  }
}
