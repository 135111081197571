import {Component, Input, OnInit} from '@angular/core';
import {UserService} from "../../gateways/services/userService/user.service";
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-modal-user-contact',
  templateUrl: './modal-user-contact.page.html',
  styleUrls: ['./modal-user-contact.page.scss'],
})
export class ModalUserContactPage implements OnInit {
  @Input() userId: number;

  public user : any = null;

  constructor(
    public usersService : UserService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.usersService.getUserById(this.userId).subscribe((user) => {
      this.user = user;
    });
  }

  async dismissModal(){
    await this.modalController.dismiss();
  }

}
