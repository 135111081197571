import { Injectable } from '@angular/core';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  public isDesktop = false;

  constructor(
    public platform: Platform
  ) { }

  getplatform(){
    const plt = this.platform.platforms();
    const findPlt = plt.findIndex(x => x === 'hybrid' || x === 'mobile' || x === 'mobileweb');
    if(findPlt === -1){
      this.isDesktop = true;
    }
  }
}
