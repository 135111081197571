import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DeliveryDto} from '../../gateways/dtos/delivery-dto';

@Component({
  selector: 'app-modal-show-result',
  templateUrl: './modal-show-result.page.html',
  styleUrls: ['./modal-show-result.page.scss'],
})
export class ModalShowResultPage implements OnInit {

  @Input() resultat;

  public delivery: DeliveryDto = null;

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.delivery = this.resultat.deliveries[0];
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
