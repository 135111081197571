import {ContainerTypeDto} from "../../../gateways/dtos/containerType-dto";

export class ContainerType implements ContainerTypeDto{
  color: string;
  companyId: number;
  emptyDestinationId: number;
  fullDestinationId: number;
  icon: string;
  id: number;
  name: string;
  regex: string;
  treatment: string;

  constructor(containerType?: ContainerType) {
    if (containerType) { Object.assign(this, containerType); }
    else {
      Object.assign(this, {
        color: '', company_id: null, emptyDestinationId: null, fullDestinationId: null, icon: '', id: null,
        name: '', regex: '', treatment: 'INTERNAL'
      });
    }
  }
}
