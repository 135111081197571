import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SuiviIPPageRoutingModule } from './suivi-ip-routing.module';

import { SuiviIPPage } from './suivi-ip.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SuiviIPPageRoutingModule
  ],
  declarations: [SuiviIPPage]
})
export class SuiviIPPageModule {}
